import * as $ from "jquery";
import * as objectFitImages from "object-fit-images";
import * as svg4everybody from "svg4everybody";
import * as isotope from "isotope-layout";
import * as imagesLoaded from "imagesloaded";
import * as jQueryBridget from "jquery-bridget";
import "jquery-validation";
import "jquery-validation-unobtrusive";
import "lazysizes";
import "picturefill";
import "responsive-bp";
import "slick-carousel";
import "@fancyapps/fancybox";

import Autocomplete from "./components/autocomplete";
import Navigation from "./components/navigation";
import uiHelpers from "./ui-helpers";

svg4everybody();
uiHelpers.init();
objectFitImages();
Autocomplete.init();

jQueryBridget("isotope", isotope, $);
jQueryBridget("imagesLoaded", imagesLoaded, $);

// include any plugin initialisation here
// any custom JS that needs to be written should be modular (and in a separate file), possibly done as a jQuery plugin or some other modular form, and initialised here like the other plugins
$(".no-js").removeClass("no-js");

// Send scrolling events to Google Analytics http://scrolldepth.parsnip.io/
if (typeof $.scrollDepth === "function") {
  $.scrollDepth();
}

// tslint:disable-next-line
new Navigation(".js-menu-toggle", "#primary-navigation");

// Search
$(".js-search-open").click((e: { preventDefault: () => void }) => {
  $("body").toggleClass("no-scroll");
  $(".primary-search__modal").toggleClass("is-visible");
  e.preventDefault();
});

$(".js-search-close").click(() => {
  $("body").removeClass("no-scroll");
  $(".primary-search__modal").removeClass("is-visible");
});

$(document).keyup((e: { keyCode: number }) => {
  if (e.keyCode === 27) {
    if ($(".primary-search__modal").hasClass("is-visible")) {
      $("body").removeClass("no-scroll");
      $(".primary-search__modal").removeClass("is-visible");
    }
  }
});

// Highlighting first/last words of headings
$(".panel h1, .panel h2").html(function() {
  const text = $(this)
    .text()
    .split(" ");
  const last = text.pop();
  return (
    text.join(" ") +
    (text.length > 0 ? ' <span class="last">' + last + "</span>" : last)
  );
});

// Slick
$(".js-carousel").slick({
  arrows: true,
  dots: true,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        arrows: true,
        slidesToShow: 1
      }
    }
  ],
  slidesToShow: 3
});

// Accordions
const acc = document.getElementsByClassName("js-accordion");
let i: number;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    const panel = this.nextElementSibling;

    this.parentNode.classList.toggle("active");

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  });
}

// Tabbed content
$(".js-tab").on("click", function() {
  const index = $(this).index() + 1;
  const tabs = $(this)
    .parent()
    .next();

  $(this)
    .addClass("active")
    .siblings()
    .removeClass("active");
  tabs.find("> div").removeClass("active");
  tabs.find("> div:nth-child(" + index + ")").addClass("active");
});

// Event date filtering
$(".js-event-filter > li:first-child").addClass("active");
$(".js-event-date:first-child").addClass("active");

$(".js-event-dates").on("click", ".js-event-filter > li", function() {
  const filter = $(this).data("filter");
  $(this)
    .addClass("active")
    .siblings()
    .removeClass("active");
  $(this)
    .closest(".js-event-dates")
    .next()
    .find(".js-event-date")
    .hide();
  $(this)
    .closest(".js-event-dates")
    .next()
    .find(".js-event-date[data-filter*='" + filter + "']")
    .show();
});

// Localstorage helpers
function setWithExpiry(key, value, expiryHours) {
  const now = new Date();
  const item = {
    expiry: now.getTime() + expiryHours * 60 * 60 * 1000,
    value
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // If the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  // var checkExpiryDate = new Date(item.expiry);
  // console.log(checkExpiryDate);

  // Compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

// Hide page behind modal which requires the user to submit the form before accessing underlying page
const popoverForms = $(".form-popover form");

popoverForms.each(function() {
  const formEl = $(this);
  const modalEl = formEl.closest(".bb-modal");
  const submitBtn = $('button[type="submit"]', formEl);
  const persistenceId = modalEl.attr("data-persistence-id");
  const alreadySubmitted = getWithExpiry(persistenceId);

  if (!alreadySubmitted) {
    modalEl.removeClass("bb-modal--hidden-by-default");

    // Hijack form to post using AJAX to avoid CDN issues
    formEl.on("submit", e => {
      e.preventDefault();

      if (formEl.data("submitted") === true) {
        // Previously submitted - don't submit again
        console.log("Form already submitted. Duplicate submission cancelled");
      } else {
        if (formEl.valid()) {
          // Disable submit button
          submitBtn.prop("disabled", true);

          // Mark it so that the next submit can be ignored
          formEl.data("submitted", true);

          // Make ajax call form submission
          $.ajax({
            cache: false,
            data: formEl.serialize(),
            success(result) {
              submitBtn.prop("disabled", false);

              // Determine whether server-side validation has passed (we can't reliably validate conditional hidden inputs client-side)
              const resultObj = $(result);
              const submitMessage = $(
                ".umbraco-forms-submitmessage",
                resultObj
              );

              if (submitMessage.length) {
                // Successful submission. Close modal and set cookie.
                modalEl.addClass("bb-modal--dismissed");
                setWithExpiry(persistenceId, "true", 0.75);
              } else {
                // If there are server-side validation errors, inject them into the UI (either in place of validation-summary-valid or validation-summary-errors element)
                const validationErrors = $(
                  ".validation-summary-errors",
                  resultObj
                );
                if (validationErrors.length) {
                  let validationEl = $(".validation-summary-valid", formEl);
                  if (!validationEl.length) {
                    validationEl = $(".validation-summary-errors", formEl);
                  }
                  validationEl.replaceWith(validationErrors);
                }
                formEl.data("submitted", false);
              }
            },
            type: "POST",
            url: formEl.attr("action")
          });
        }
      }
    });
  }
});

// Showcase masonry
const gridOptions = {
  itemSelector: ".isotope-grid__item",
  masonry: {
    gutter: 20
  },
  percentPosition: true
};

const $grid = $(".isotope-grid").isotope({
  filter: "*",
  ...gridOptions
});

$grid.imagesLoaded(() => {
  setTimeout(() => {
    $grid.isotope("layout").removeClass("isotope-grid--unloaded");
  }, 1000);
});

// Remove any unused filter options
$(".js-showcase-filter").each(function() {
  const name = $(this).attr("name");
  const contentfilters = $(".js-showcase-item[data-" + name + "]")
    .map(function() {
      return $(this).data(name);
    })
    .get()
    .join(",");

  let filter = contentfilters.split(",");
  const uniqueFilters = new Set(filter);
  filter = Array.from(uniqueFilters).sort();
  filter.push(filter.splice(filter, 1)[0]);

  $(this)
    .find("option")
    .each(function() {
      if ($(this).val() !== "" && $.inArray($(this).val(), filter) === -1) {
        $(this).remove();
      }
    });
});

// Perform filtering using selected options
const filters = {};

function getFilterValues() {
  let filterQueryString = "?";
  $(".js-showcase-filter").each((i, ele) => {
    const filterGroup = $(ele).attr("name");
    const selectValue = $(ele)
      .find(":selected")
      .val();

    if (selectValue) {
      filterQueryString += filterGroup + "=" + selectValue + "&";
    }
  });

  filterQueryString = filterQueryString.slice(0, -1);

  if (filterQueryString === "") {
    const href = window.location.href.split("?")[0];
    window.history.pushState({ path: href }, "", href);
  } else {
    window.history.pushState(
      { path: filterQueryString },
      "",
      filterQueryString
    );
  }
}

function reshuffle() {
  const filterValue = concatValues(filters);

  $grid.isotope({
    filter: filterValue,
    ...gridOptions
  });

  if (!$grid.data("isotope").filteredItems.length) {
    $(".js-showcase-no-results").addClass("active");
  } else {
    $(".js-showcase-no-results").removeClass("active");
  }
}

$(".js-showcase-filter").on("input", e => {
  const $select = $(e.target);
  const filterGroup = $select.attr("name");
  if (e.target.value) {
    filters[filterGroup] = e.target.value;
  } else {
    filters[filterGroup] = "";
  }

  reshuffle();

  getFilterValues();
});

function concatValues(obj) {
  let value = "";
  for (const prop in obj) {
    if (prop && obj[prop] !== "") {
      value += "." + obj[prop];
    }
  }
  return value;
}

// Showcase modal handling
$(".js-showcase-item").on("click", function(e) {
  const href = $(this).attr("href");
  const content = $(this)
    .next(".js-showcase-modal-source")
    .html();
  if (content == null) {
    return false;
  }
  $("#showcase-modal .js-showcase-modal-target").html(content);
  $("#showcase-modal").removeClass("bb-modal--hidden-by-default");
  window.history.pushState({ path: href }, "", href);
  e.preventDefault();
});

$(".js-showcase-modal-close").on("click", function(e) {
  const href = $(this).attr("href");
  $("#showcase-modal").addClass("bb-modal--hidden-by-default");
  window.history.pushState({ path: href }, "", href);
  $("#showcase-modal .js-showcase-modal-target").html("");
  e.preventDefault();
});

// Display selected showcase entry if provided in URL
const getUrlParameter = function getUrlParameter(sParam) {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split("&");
  let sParameterName;
  let i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
};

if (
  window.location.href.indexOf("?") > -1 &&
  getUrlParameter("entry") !== false
) {
  $(".js-showcase-item[href='" + window.location.href + "']").trigger("click");
}

if (
  window.location.href.indexOf("?") > -1 &&
  (getUrlParameter("theme") !== false ||
    getUrlParameter("age") !== false ||
    getUrlParameter("region") !== false)
) {
  if (getUrlParameter("theme") !== false) {
    const themeFilter = "theme";
    $(".js-showcase-filter[name='theme']").val(getUrlParameter(themeFilter));
    filters[themeFilter] = getUrlParameter(themeFilter);
  }
  if (getUrlParameter("age") !== false) {
    const ageFilter = "age";
    $(".js-showcase-filter[name='age']").val(getUrlParameter(ageFilter));
    filters[ageFilter] = getUrlParameter(ageFilter);
  }
  if (getUrlParameter("region") !== false) {
    const regionFilter = "region";
    $(".js-showcase-filter[name='region']").val(getUrlParameter(regionFilter));
    filters[regionFilter] = getUrlParameter(regionFilter);
  }
  reshuffle();
}
