// Navigation

class Navigation {
  static DEFAULTS: {
    activeClass: string;
    bodyNoScrollClass: string;
    overlayClass: string;
  };
  toggle: Element;
  container: Element;
  activeMenuItem: Element;
  activeSubMenu: Element;
  overlay: HTMLDivElement;
  isMenuOpen: boolean;
  settings: {
    activeClass: string;
    bodyNoScrollClass: string;
    overlayClass: string;
  };

  constructor(toggle: string, container: string, options?: object) {
    this.toggle = document.querySelector(toggle);
    this.container = document.querySelector(container);
    this.activeMenuItem = null;
    this.isMenuOpen = false;
    this.settings = {
      ...Navigation.DEFAULTS,
      ...options
    };

    this.init();
  }

  init() {
    // Move menus to end
    this.container.querySelectorAll(".js-menu-parent").forEach(element => {
      const submenu = this.getSubMenu(element);

      if (submenu) {
        this.container.insertAdjacentElement("afterend", submenu);
      }
    });

    // Create overlay
    this.overlay = document.createElement("div");
    this.overlay.classList.add("primary-navigation-overlay");
    this.overlay.setAttribute("hidden", "");
    document.body.appendChild(this.overlay);

    this.bindHandlers();
  }

  bindHandlers() {
    this.toggle.addEventListener("click", this.handleToggleClick.bind(this));
    document.addEventListener("click", this.handleOutsideClick.bind(this));

    document.addEventListener("click", e => {
      const target = e.target as Element;

      if (target.closest(".js-menu-parent")) {
        this.handleMenuItemClick(e);
      }

      if (target.closest(".js-menu-back")) {
        this.handleBackBtnClick(e);
      }
    });
  }

  handleToggleClick(e: Event) {
    if (!this.isMenuOpen) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  handleMenuItemClick(e: Event) {
    e.preventDefault();

    const menuItem = e.target as Element;
    const isExpanded = menuItem.getAttribute("aria-expanded") === "true";

    if (this.activeMenuItem) {
      this.closeSubMenu(this.activeMenuItem);
    }

    if (isExpanded) {
      this.closeSubMenu(menuItem);
    } else {
      this.openSubMenu(menuItem);
    }
  }

  handleBackBtnClick(e: Event) {
    this.closeSubMenu(this.activeMenuItem);
  }

  handleOutsideClick(e: Event) {
    const target = e.target as Element;

    if (
      (!this.container.contains(target) &&
        this.activeSubMenu &&
        !this.activeSubMenu.contains(target)) ||
      this.overlay.contains(target)
    ) {
      this.closeMenu();

      if (this.activeMenuItem) {
        this.closeSubMenu(this.activeMenuItem);
      }
    }
  }

  openMenu() {
    const { activeClass, bodyNoScrollClass } = this.settings;

    window.scrollTo(0, 0);

    this.toggle.classList.add(activeClass);
    this.container.classList.add(activeClass);
    document.body.classList.add(bodyNoScrollClass);

    this.showOverlay();

    this.isMenuOpen = true;
  }

  closeMenu() {
    const { activeClass, bodyNoScrollClass } = this.settings;

    this.toggle.classList.remove(activeClass);
    this.container.classList.remove(activeClass);
    document.body.classList.remove(bodyNoScrollClass);

    this.hideOverlay();

    this.isMenuOpen = false;
  }

  openSubMenu(menuItem: Element) {
    const { activeClass } = this.settings;
    const subMenu = this.getSubMenu(menuItem);

    menuItem.setAttribute("aria-expanded", "true");
    subMenu.removeAttribute("hidden");
    subMenu.classList.add(activeClass);

    this.activeMenuItem = menuItem;
    this.activeSubMenu = subMenu;

    if (!this.isMenuOpen) {
      this.showOverlay();
    }
  }

  closeSubMenu(menuItem: Element) {
    const { activeClass } = this.settings;

    menuItem.setAttribute("aria-expanded", "false");
    this.activeSubMenu.classList.remove(activeClass);
    this.activeSubMenu.setAttribute("hidden", "");

    this.activeMenuItem = null;
    this.activeSubMenu = null;

    if (!this.isMenuOpen) {
      this.hideOverlay();
    }
  }

  showOverlay() {
    this.overlay.removeAttribute("hidden");
  }

  hideOverlay() {
    this.overlay.setAttribute("hidden", "");
  }

  getSubMenu(menuItem: Element) {
    const menuId = menuItem.getAttribute("aria-controls");
    return document.getElementById(menuId);
  }
}

Navigation.DEFAULTS = {
  activeClass: "active",
  bodyNoScrollClass: "no-scroll",
  overlayClass: "primary-navigation-overlay"
};

export default Navigation;
