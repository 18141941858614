import * as accessibleAutocomplete from "accessible-autocomplete";

function init() {
  const autocompleteContainer = document.querySelector(
    "#autocomplete-container"
  );

  if (!autocompleteContainer) {
    return;
  }

  let resultsLoaded = false;

  function fetchStuff(query, populateResults) {
    resultsLoaded = false;
    fetch("/umbraco/api/schoollookup/schools?q=".concat(query, "&limit=20"))
      .then(response => {
        return response.json();
      })
      .then(json => {
        populateResults(json);
        resultsLoaded = true;
      })
      .catch(error => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  }

  const name = autocompleteContainer.getAttribute("data-name");
  const id = autocompleteContainer.getAttribute("data-id");
  const defaultValue = autocompleteContainer.getAttribute("data-default");
  const formAttr = autocompleteContainer.getAttribute("data-form");
  const required = autocompleteContainer.getAttribute("data-required");
  const callbackName = autocompleteContainer.getAttribute("data-on-confirm");
  const requiredMessage = autocompleteContainer.getAttribute(
    "data-val-required"
  );

  accessibleAutocomplete({
    cssNamespace: "Search-bar",
    defaultValue,
    displayMenu: "overlay",
    element: autocompleteContainer,
    id,
    name,
    onConfirm: function onConfirm(confirmed) {
      if (
        confirmed &&
        callbackName &&
        typeof window[callbackName] === "function"
      ) {
        window[callbackName](confirmed);
      }
    },
    placeholder: "Enter school name or postcode",
    required,
    showNoOptionsFound: true,
    source: fetchStuff,
    tNoResults: function tNoResults() {
      if (!resultsLoaded) {
        return "Searching...";
      } else {
        return "No results found";
      }
    },
    templates: {
      inputValue: function inputValue(value) {
        if (typeof value === "string") {
          return value;
        }

        return value && "".concat(value.name, " -- ").concat(value.postcode);
      },
      suggestion: function suggestion(value) {
        if (typeof value === "string") {
          const split = value.split(" -- ");

          if (split.length === 2) {
            return "<span>"
              .concat(split[0], "</span><span>")
              .concat(split[1], "</span>");
          } else {
            return "";
          }
        }

        return "<span>"
          .concat(value.name, "</span><span>")
          .concat(value.postcode, "</span>");
      }
    }
  });

  const input = document.querySelector(
    ".Search-bar__input"
  ) as HTMLInputElement;

  if (formAttr) {
    input.setAttribute("form", formAttr);
  }

  if (required) {
    input.setAttribute("data-val-required", requiredMessage);
    input.setAttribute("data-val", "true");
  }

  input.addEventListener("blur", e => {
    const selectedOptionInput = autocompleteContainer.nextElementSibling as HTMLInputElement;
    const errorMsg = autocompleteContainer.nextElementSibling.getAttribute(
      "data-val-required"
    );
    const searchErrorEl = autocompleteContainer.parentNode.lastElementChild;

    if (input.value === "") {
      selectedOptionInput.value = "";
    }

    if (selectedOptionInput.value === "") {
      searchErrorEl.textContent = errorMsg;
      searchErrorEl.className = "field-validation-error";
    } else {
      searchErrorEl.textContent = "";
      searchErrorEl.className = "field-validation-valid";
    }
  });
}

export default { init };
